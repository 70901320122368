import React, { useState, useRef } from "react";

export default function BinPackingSolver() {
  const [parts, setParts] = useState("5,8,55,95,74,34,62,25,12,14,36,35,10,24,19,31,20,10,14,15,16,85,24,12,90,45,76,53,81,72");
  const [stockLength, setStockLength] = useState(100);
  const [bestSolution, setBestSolution] = useState(null);
  const [solutionsTried, setSolutionsTried] = useState(0);
  const [unprunedRemaining, setUnprunedRemaining] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [interrupted, setInterrupted] = useState(false);
  const [progress, setProgress] = useState(0); // New state for tracking progress
  const cancelRef = useRef(false);

  const handleCancel = () => {
    cancelRef.current = true;
    setIsRunning(false);
  };

  const handleAccept = () => {
    setIsRunning(false);
  };

  const firstFitDecreasing = (sortedParts, stockLength) => {
    const bins = [];
    for (let part of sortedParts) {
      let placed = false;
      for (let bin of bins) {
        if (bin.reduce((a, b) => a + b, 0) + part <= stockLength) {
          bin.push(part);
          placed = true;
          break;
        }
      }
      if (!placed) {
        bins.push([part]);
      }
    }
    return bins;
  };

  const calculateWaste = (bins, stockLength) => {
    const totalStockUsed = bins.length * stockLength;
    const totalPartsLength = bins.flat().reduce((a, b) => a + b, 0);
    return ((totalStockUsed - totalPartsLength) / totalStockUsed) * 100;
  };

  const branchAndBound = (sortedParts, stockLength, upperBound) => {
    let bestBins = firstFitDecreasing(sortedParts, stockLength);
    let bestNumBins = bestBins.length;
    let solutionsTriedCount = 0;
    let unprunedRemainingCount = 1;


    const explore = async (currentBins, remainingParts) => {
        if (cancelRef.current || interrupted) return;
      
        solutionsTriedCount++;
        setSolutionsTried(solutionsTriedCount);
      
        const progressEstimate = (solutionsTriedCount / upperBound) * 100;
        setProgress(progressEstimate);
      
        if (currentBins.length >= bestNumBins) return;
      
        if (remainingParts.length === 0) {
          if (currentBins.length < bestNumBins) {
            bestBins = currentBins;
            bestNumBins = currentBins.length;
            setBestSolution({ bins: bestBins, waste: calculateWaste(bestBins, stockLength) });
          }
          return;
        }
  
        const nextPart = remainingParts[0];
        const restParts = remainingParts.slice(1);
      
        for (let i = 0; i < currentBins.length; i++) {
          if (currentBins[i].reduce((a, b) => a + b, 0) + nextPart <= stockLength) {
            const newBins = currentBins.map((bin, index) =>
              index === i ? [...bin, nextPart] : bin
            );
            await explore(newBins, restParts);  // Await the recursive call
          }
        }
      
        const newBins = [...currentBins, [nextPart]];
        unprunedRemainingCount++;
        setUnprunedRemaining(unprunedRemainingCount);
        await explore(newBins, restParts);  // Await the recursive call
      };
      
      // Adjust the asyncExplore function to include async calls.
      const asyncExplore = async () => {
        const chunkSize = 50; 
        let index = 0;
      
        while (index < sortedParts.length) {
          if (cancelRef.current || interrupted) return;
      
          const currentChunk = sortedParts.slice(index, index + chunkSize);
          await explore([], currentChunk);  // Await the explore function
          index += chunkSize;
      
          await new Promise(resolve => setTimeout(resolve, 0));  // Yield control back to the browser
        }
      };
    // Running the search in chunks asynchronously


    asyncExplore();
  };

  const handleStart = () => {
    const partList = parts
      .split(",")
      .map((p) => parseInt(p.trim()))
      .filter((n) => !isNaN(n));
    const sortedParts = [...partList].sort((a, b) => b - a);

    cancelRef.current = false;
    setIsRunning(true);
    setSolutionsTried(0);
    setUnprunedRemaining(0);
    setInterrupted(false);
    setProgress(0);  // Reset progress at the start

    const ffdBins = firstFitDecreasing(sortedParts, stockLength);
    setBestSolution({ bins: ffdBins, waste: calculateWaste(ffdBins, stockLength) });

    // Estimate upper bound for the progress tracker
    const estimatedSolutions = Math.pow(2, sortedParts.length); // This is an upper bound

    branchAndBound(sortedParts, stockLength, estimatedSolutions);
  };

  return (
    <div className="p-4 space-y-4">
      <h1 className="text-xl font-bold">Bin Packing Solver</h1>

      <div className="space-y-2">
        <div>
          <label className="block font-medium">Part Lengths (comma-separated)</label>
          <input
            type="text"
            className="w-full p-2 border rounded"
            value={parts}
            onChange={(e) => setParts(e.target.value)}
            disabled={isRunning}
          />
        </div>

        <div>
          <label className="block font-medium">Stock Length</label>
          <input
            type="number"
            className="w-full p-2 border rounded"
            value={stockLength}
            onChange={(e) => setStockLength(parseInt(e.target.value))}
            disabled={isRunning}
          />
        </div>
      </div>

      <button
        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
        onClick={handleStart}
        disabled={isRunning}
      >
        Start
      </button>

      {isRunning && (
        <div>
          <button
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-white bg-yellow-500 rounded hover:bg-yellow-600"
            onClick={handleAccept}
          >
            Accept Current Solution
          </button>
        </div>
      )}

      {bestSolution && (
        <div className="mt-4 p-4 border rounded">
          <h2 className="text-lg font-bold">Best Solution</h2>
          <p>Total Bins: {bestSolution.bins.length}</p>
          <p>Waste Percentage: {bestSolution.waste.toFixed(2)}%</p>
          <div className="mt-2">
            <h3 className="font-medium">Bins:</h3>
            <pre className="p-2 bg-gray-100 rounded">{JSON.stringify(bestSolution.bins, null, 2)}</pre>
          </div>
        </div>
      )}

      {isRunning && (
        <div className="mt-4">
          <p>Progress: {Math.round(progress)}%</p>
          <div className="w-full bg-gray-200 rounded-full h-4">
            <div
              className="bg-blue-500 h-4 rounded-full"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      )}

      <div className="mt-4 p-4 border rounded">
        <h2 className="text-lg font-bold">Statistics</h2>
        <p>Solutions Tried: {solutionsTried}</p>
        <p>Unpruned Remaining: {unprunedRemaining}</p>
      </div>
    </div>
  );
}
